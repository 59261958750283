$(document).ready(function(){
  if (window.location.href.indexOf("#blue_ocean_women") !== -1)
  {
    window.location.href = '/lcl/blue_ocean/quiz/intro_choose.tmpl#womensQuiz';
  }
  else if (window.location.href.indexOf("#blue_ocean_men") !== -1)
  {
    window.location.href = '/lcl/blue_ocean/quiz/intro_choose.tmpl#mensQuiz';
  }
  else if (window.location.href.indexOf("#blue_ocean") !== -1)
  {
    window.location.href = '/lcl/blue_ocean/quiz/intro_choose.tmpl';
  } 
});
